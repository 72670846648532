<template>
  <mobile-screen
    :header="true"
    :screen-class="
      [
        'r_plan-map-help-online',
        'r_plan-slots-map-help-online',
        'r_plan-resources-map-help-online',
      ].includes($route.name)
        ? 'gray-bg fluid'
        : 'gray-bg fluid show-footer-only-on-last-screen'
    "
  >
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="resource-header icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: helpLinkName,
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{ name: backLinkName || 'r_plan', params: $route.params }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          Map
        </div>
      </top-header-menu-wrapper>
    </template>
    <section
      class="map-iframe-wrapper"
      style="display: flex; width: 100%; position: relative; height: 100%"
      v-if="url"
    >
      <iframe
        ref="mapIframe"
        style="position: absolute; top: 0; left: 0; width: 100%; bottom: 0; border: none; overflow: auto"
        :src="url"
        frameborder="0"
        width="100%"
        height="100%"
      ></iframe>
    </section>
    <template v-slot:footer>
      <plans-footer-menu
        ><button v-if="showDeclareIcon" @click="showModal = true">
          <span class="svg-icon"><icon icon="#cx-foo2-submit-38x38"/></span>
        </button>
        <a v-else-if="showCameraButton" href="#"
          ><icon icon="#cx-foo2-qrcode-38x38"/></a
      ></plans-footer-menu>
    </template>
    <screen-modal
      v-if="showModal"
      class="confirm-modal"
      type="success"
      :confirm-button-label="displayLabelName('plan', 'plan', 'ok')"
      :cancel-button-label="displayLabelName('plan', 'plan', 'cancel')"
      :confirm-action="declareSlot"
      :show="showModal"
      @close="showModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("plan", "plan", "declaration-confirmed") }}
      </h3>
      <template v-if="type && slots">
        <p class="text">
          <time
            v-if="['DAY', 'HALF_DAY'].includes(type)"
            :datetime="displayDate(slots[0].datetime_from)"
            >{{ displayDate(slots[0].datetime_from) }}</time
          >
          <time
            v-else
            :datetime="
              `${displayTime(slots[0].datetime_from)} - ${displayTime(
                slots[0].datetime_to
              )}`
            "
            >{{ displayTime(slots[0].datetime_from) }} -
            {{ displayTime(slots[0].datetime_to) }}</time
          >
        </p>
        <p class="text">{{ name }}</p>
      </template>
    </screen-modal>
  </mobile-screen>
  <router-view></router-view>
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import PlansFooterMenu from "@/components/plans/PlansFooterMenu";
import httpServiceAuth, {
  getLang,
  getSubdomain,
} from "@/services/http-service";
import PlanMixin from "@/services/mixins/plan/plan-mixin";
import { apiEndpoints } from "@/services/constants";
import { mapState, mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";
import qs from "qs";
import { errorHandler } from "@/services/error-handler";
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import mapMixin from "@/services/mixins/map/map";

export default {
  name: "Plan5dMap",
  data() {
    return {
      url: null,
      showModal: false,
      showDeclareIcon: false,
      resourceId: null,
      slots: null,
      type: null,
      name: null,
      helpSlug: "plan-resources-5dmap",
    };
  },
  created() {
    this.getCompanyPreferences(10);
    if (!this.hasMap) {
      this.$router.push({ name: this.backLinkName || "r_plan" });
    }
    this.$store.commit("global/setIsFullWidthScreen", true, {
      root: true,
    });
    window.addEventListener("message", this.readMessage, false);
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("companyPreferences", ["preferences"]),
    ...mapState("user", ["company"]),
    ...mapState("plan", [
      "selectedLevel",
      "planSelectedDate",
      "selectedSlot",
      "slots",
      "selectedResource",
      "selectedResourceType",
    ]),
    ...mapState("searchFiltersPlanResources", [
      "selectedAttributesIds",
      "sections",
    ]),
    showCameraButton() {
      if (this.preferences) {
        return Boolean(
          Number(
            this.preferences.filter(
              (p) => p.preference == "SHOW_CAMERA_BUTTON"
            )[0].preference_values[0].value
          )
        );
      }
      return false;
    },
    hasMap() {
      return this.selectedLevel && this.selectedLevel.map_id;
    },
    displayMapData() {
      const { company, selectedLevel } = this;
      if (company && company.data && company.data.uuid && selectedLevel) {
        return {
          company_key: company.data.uuid,
          map_key: selectedLevel.map_id,
        };
      }
      return null;
    },
  },
  watch: {
    displayMapData: {
      handler() {
        if (this.displayMapData) {
          const { map_key, company_key } = this.displayMapData;
          this.displayMap(map_key, company_key);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions("companyPreferences", ["getCompanyPreferences"]),
    async displayMap() {
      const envVariables = process.env;
      const { VUE_APP_5DMAPS_URL } = envVariables;
      const subdomain = getSubdomain();
      let subdomainUrl = "";
      if (subdomain) subdomainUrl = `&subdomain=${subdomain}`;
      let slotsUrl = "";
      if (this.selectedSlot) {
        slotsUrl = qs.stringify(this.selectedSlot);
      } else {
        if (this.slots && this.slots.data && this.slots.data.length) {
          const availableSlots = this.slots.data[
            this.slots.data.length - 1
          ].slots.filter((item) => item.available);
          if (availableSlots.length) {
            slotsUrl = qs.stringify({
              type: this.slots.data[this.slots.data.length - 1].type,
              slots: availableSlots[0],
            });
          }
        } else {
          const slots = await this.getRequiredSlots(this.planSelectedDate);
          if (
            slots &&
            slots.data &&
            slots.data.data &&
            slots.data.data[0] &&
            slots.data.data[0].slots
          ) {
            const availableSlots = slots.data.data[0].slots.filter(
              (item) => item.available
            );
            if (availableSlots.length) {
              slotsUrl = qs.stringify({
                type: slots.data.data[0].type,
                slots: {
                  from: availableSlots[0].from,
                  to: availableSlots[0].to,
                },
              });
            }
          }
        }
      }
      let selectedResourceId = "";

      if (this.selectedResource && this.selectedResource.id) {
        selectedResourceId = `&resource_id=${this.selectedResource.id}`;
      }

      let selectedResourceTypes = "";
      if (
        this.$store._state.data.searchFiltersPlanResources.selectedTypeIds
          .length > 0
      ) {
        for (
          var i = 0;
          i <
          this.$store._state.data.searchFiltersPlanResources.selectedTypeIds
            .length;
          i++
        ) {
          selectedResourceTypes +=
            "&types[" +
            i +
            "]=" +
            this.$store._state.data.searchFiltersPlanResources.selectedTypeIds[
              i
            ];
        }
      }

      this.url = `${VUE_APP_5DMAPS_URL}/view/${
        this.selectedLevel.map_id
      }?api=${this.getMapApi()}&access_token=${localStorage.getItem(
        "atApp"
      )}&language=${getLang()}&mode=plan${subdomainUrl}&${slotsUrl}${selectedResourceId}${selectedResourceTypes}`;
    },
    readMessage(event) {
      const envVariables = process.env;
      const { data } = event;
      const { VUE_APP_5DMAPS_URL } = envVariables;

      if (event.origin === VUE_APP_5DMAPS_URL) {
        this.resourceId = null;
        const levelId = data.LevelID;
        if (data.ResourceID) {
          this.resourceId = data.ResourceID;
        } else if (data.resourceId) {
          this.resourceId = data.resourceId;
        }
        const eventName = data.eventName;
        const query = {};

        if (eventName === "calendarOpen") {
          this.$router.push({
            name: this.backLinkName,
            params: this.$route.params,
          });
        }

        if (eventName === "resourceChange") {
          this.showDeclareIcon = false;
        }

        if (data.Slots && data.Type) {
          this.slots = data.Slots;
          this.type = data.Type;
          localStorage.setItem(
            "mapSlots",
            JSON.stringify({
              slots: this.slots,
              type: this.type,
            })
          );
        }
        if (data.slots && data.type) {
          this.slots = data.slots;
          this.type = data.type;
          localStorage.setItem(
            "mapSlots",
            JSON.stringify({
              slots: this.slots,
              type: this.type,
            })
          );
        }
        if (data.name) {
          this.name = data.name;
        }
        if (levelId) {
          query.levelId = levelId;
        }

        if (this.resourceId) {
          query.resourceId = this.resourceId;
        }

        if ((levelId || this.resourceId) && eventName !== "makeDeclaration") {
          this.$router.push({ name: this.backLinkName || "r_plan", query });
        }
        if (eventName === "makeDeclaration") {
          this.showDeclareIcon = true;
        }
      }
    },
    async getRequiredSlots(date = null) {
      let dateForSlots = date ? date : dayjs().format("YYYY-MM-DD");
      const { $store } = this;
      $store.commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.slots}/default`, {
          params: {
            types: ["QUARTER_HOUR"],
            date: dateForSlots,
            timezone: dayjs.tz.guess(),
          },
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.show = false;
        });
    },
    declareSlot() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      const dataToSend = {
        slots: this.slots,
        type: this.type,
      };
      httpServiceAuth
        .post(
          `${apiEndpoints.company.declarations}/resources/${this.resourceId}`,
          dataToSend
        )
        .then(() => {
          const mapData = qs.stringify({
            declarationCreated: 1,
          });
          this.$refs.mapIframe.contentWindow.postMessage(
            {
              call: "declarationCreated",
              value: mapData,
            },
            "*"
          );
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.showDeclareIcon = false;
          this.$store.commit("loader/setScreenLoading", false, {
            root: true,
          });
        });
    },
    displayDate(date) {
      if (!date) {
        return;
      }
      return formatDate(date, this.globalDateFormat);
    },
    displayTime(time) {
      if (!time) {
        return;
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    },
  },
  beforeUnmount() {
    this.$store.commit("global/setIsFullWidthScreen", false, {
      root: true,
    });
    window.removeEventListener("message", this.readMessage);
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    PlansFooterMenu,
  },
  mixins: [PlanMixin, helpOnlineMixin, mapMixin],
  props: {
    backLinkName: {
      type: String,
    },
    helpLinkName: {
      type: String,
    },
  },
};
</script>
